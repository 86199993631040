import DataTable from "examples/Tables/DataTable";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { getAdmins } from "components/api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "./pagination.css";
import Holder from "./tableholder";

//select
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function Admins() {
  const columns = [
    {
      Header: "",
      id: "row",
      Cell: (index) => {
        return <div>{index.row.index + 1}</div>;
      },
    },
    { Header: "First Name", accessor: "first_name" },
    { Header: "Last Name", accessor: "last_name" },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ value }) => {
        return <span>{moment(value).format("LLL")}</span>;
      },
    },
    { Header: "Status", accessor: "status", align: "center" },
  ];

  const [users, setUsers] = useState([]);
  const rows = [...users];
  //console.log(rows, users);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [firstRun, setFirstRun] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      fetchUsers(page, size);
    }
  }, [firstRun]);

  const handlePageClick = async (e) => {
    const newpage = e.selected;
    setPage(newpage);
    fetchUsers(newpage, size);
  };

  const fetchUsers = async (page, size) => {
    setLoading(true);
    const userslist = await getAdmins(page, size);
    setLoading(false);
    if (userslist.status === 200) {
      //console.log(userslist.data.data);
      setUsers(userslist.data.data.admins);
      setPageCount(userslist.data.data.pages);
      setTotal(userslist.data.data.total);
    } else {
      toast.error("Unknown error Occured", {
        position: "bottom-left",
      });
    }
  };

  const changeSize = (pagesize) => {
    if (pagesize == "") {
      return;
    }
    setSize(pagesize);
    fetchUsers(page, pagesize);
  };

  const handleDialogOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox>
            <div style={{ display: "block" }}>
              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    Admin Users
                  </MDTypography>
                  <div>
                    <MDButton variant="gradient" color="dark" onClick={handleDialogOpen}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp; create admin
                    </MDButton>
                  </div>
                </MDBox>
                {loading ? (
                  <Holder />
                ) : (
                  <DataTable
                    className="hidden"
                    table={{ columns, rows }}
                    showTotalEntries={false}
                    isSorted={false}
                    size={size}
                    noEndBorder
                    entriesPerPage={false}
                    style={{ display: "none" }}
                    pagination={false}
                  />
                )}
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right"></i>}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left"></i>}
                      containerClassName="pagination"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      activeClassName="active"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      renderOnZeroPageCount={false}
                    />
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    lineHeight={0}
                    justifyContent="space-between"
                  >
                    <div>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;Showing <strong>{users.length} </strong> users of {total}
                      </MDTypography>
                    </div>
                    <div>
                      <select
                        className="select-size"
                        onChange={(e) => changeSize(e.target.value)}
                        value={size}
                      >
                        <option value="">Page Size</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </div>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <Dialog open={open} onClose={handleDialogOpen} fullWidth>
        <DialogTitle>Create Admin</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Email Address"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="notification"
            label="Phone Number"
            type="text"
            fullWidth
            variant="standard"
          />
          <br />
          <FormControl variant="standard" sx={{ mt: 1, minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Group</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              // value={age}
              // onChange={handleChange}
              label="Role"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Admin</MenuItem>
              <MenuItem value={20}>Assistant</MenuItem>
              <MenuItem value={30}>Lead</MenuItem>
            </Select>
          </FormControl>
          <br />

          <FormControl variant="standard" sx={{ mt: 2, minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              // value={age}
              // onChange={handleChange}
              label="Group"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Marketing</MenuItem>
              <MenuItem value={20}>Development</MenuItem>
              <MenuItem value={30}>Quality assurance</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <MDBox display="flex" gap="2" alignItems="center">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Active
            </MDTypography>
            <Switch />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
          // disabled={title == "" || notebody == "" || notebody.length > 1024}
          // onClick={handleSendNotification}
          >
            Save Admin
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Admins;
